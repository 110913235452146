window.addEventListener("DOMContentLoaded", () => {
    const parents = document.querySelectorAll('[data-js="accommodations-filtered-list"]');

    parents.forEach((parent) => {
        const inputs = parent.querySelectorAll('[data-js="input"]');
        const cards = parent.querySelectorAll('[data-js="card"]');
        const filters = [];

        inputs.forEach((input) => {
            input.addEventListener('change', (e) => {
                if(e.target.checked) {
                    filters.push(e.target.value);
                } else {
                    const index = filters.indexOf(e.target.value);
                    filters.splice(index, 1);
                }

                if(filters.length === 0) {
                    cards.forEach((card) => {
                        card.style.display = 'flex';
                    });
                } else {
                    cards.forEach((card) => {
                        card.style.display = 'none';
                    });
                }

                filters.forEach((filter) => {
                    const selectedCards = parent.querySelectorAll('[data-js="card"][data-type="' + filter + '"]');

                    selectedCards.forEach((card) => {
                        card.style.display = 'flex';
                    });
                });
            })
        });
    })
});